@import "@/src/styles/common.scss";
.paper-when-no-fullheight {
    border-radius: 0.2rem 0.2rem 0 0;
}
.drawer-root {
    .drawer-box-container {
        &.drawer-box-with-header {
            padding: 0.6rem 0 0;
        }
        // fullheight
        &.drawer-box-fullheight {
            padding: 0;
            .drawer-icon {
                padding: 0;
                height: 1rem;
                border-bottom: 0.02rem solid $gray_4;
            }
            .children-container {
                padding-top: 0.4rem;
            }
        }
        &.padding-drawer {
            padding: 0.6rem 0;
            max-height: 11.6rem;
            overflow: auto;
            height: 100%;
            .drawer-icon {
                .icon_class {
                    bottom: calc(var(--box-container-height) - 0.2rem);
                    transform: translateY(100%);
                    top: unset;
                }
            }
        }
        .drawer-icon {
            // bar-menu
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center; // flex-start
            align-items: center;
            padding: 0px 0.4rem 0.4rem;
            height: auto;
            font-family: var(--font-montserrat-medium);
            font-size: 0.4rem;
            color: $text;
            // border-bottom: 0.02rem solid #ddd;
            &.closeable-position-right {
                // .closeable-position-right-then-icon-order {
                //     order: 1;
                // }
                // .no-header-and-closeable-right {
                //     position: absolute;
                //     right: 0.15rem;
                // }
            }
            &.closeable_no-header-children {
                // position: absolute;
                right: 0;
                z-index: 1;
                padding: 0;
            }
            .icon_class {
                position: fixed;
                @include use-icon-normal();
                top: 0.2rem;
                z-index: 1;
                &.closeable-position-right-then-icon-order {
                    right: 0.2rem;
                }
                &:not(.closeable-position-right-then-icon-order) {
                    left: 0.2rem;
                }
            }
        }
        .children-container {
            padding: 0 0.4rem;
            @include use-font-normal();
            height: 100%;
            overflow: auto;
            max-height: calc(100% - var(--header-footer-height));
            &.full-height {
                max-height: calc(
                    var(--box-container-height) - var(--header-footer-height)
                );
            }
            &.has-footer-children-container {
                // large-button-font-size * 1.5 + 0.24 * 2(p-tb)
            }
        }
        .footer-container {
            position: fixed;
            bottom: 0;
            width: 100%;
            background-color: #fff;
            &.vertical {
                position: relative;
                padding: 0 0.4rem;
            }
            .footer-button.footer-button {
                border-color: transparent;
                border-radius: 0;
                box-shadow: 0 0.12rem 0.24rem rgba(0, 0, 0, 0.16);
            }
        }
    }
}
