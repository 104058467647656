@import "@/src/styles/common.scss";
@import "../styles/variable.module.scss";
.form-control {
    .form-group {
        .form-control-label-container {
            // 不受label控制的label-text
            &.custom-interation {
                display: flex;
                align-items: center;
                .checked {
                    color: $text;
                }
            }
            .form-control-label {
                padding: 0;
                margin: 0;
                align-items: center;
                .checkbox-label-item {
                    margin-right: 0.3rem;
                    padding: 0;
                    border: 0.02rem solid #8e9399;
                    border-radius: 0.08rem;
                    width: 0.48rem;
                    height: 0.48rem;
                    flex-shrink: 0; //不收缩
                    &.checked-disabled-item {
                        border-color: $disabled_color;
                    }
                    &.checked-item {
                        // color: $main;
                        font-size: 0.28rem;
                        color: #fff;
                        background-color: $main;
                        border-color: transparent;
                        &.checked-disabled-item {
                            background-color: $disabled_color;
                        }
                        & ~ .form-label {
                            color: $text;
                        }
                        .checked-icon-font {
                            font-size: 0.28rem;
                        }
                    }
                }
            }
            .form-label {
                font-size: 0.28rem;
                color: #8e9399;
                line-height: 1.5;
                letter-spacing: normal;
                font-family: var(--font-montserrat);
            }
            .form-control-label + .form-control-label {
                margin-bottom: 0.2rem;
            }
        }
        .form-control-label-container + .form-control-label-container {
            margin-top: 0.3rem;
        }
    }
    .note {
        font-size: 0.26rem;
        padding: 0.14rem;
        margin: 0;
    }
}
